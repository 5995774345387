import { Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { IActivity } from '@scb-lib/models';
import { I18nDataReadPipe } from '@shared/pipes/i18n.pipe';
import { UiService } from '@shared/services/ui.service';
import { OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
	standalone: true,
	imports: [CarouselModule, I18nDataReadPipe],
})
export class BannerComponent {
	router = inject(Router);
	uiSrv = inject(UiService);

	@Input() mainSlides: IActivity[] = [];

	mainOps: OwlOptions = {
		autoplay: true,
		center: true,
		items: 1,
		margin: 0,
		stagePadding: 0,
		nav: false,
		loop: true,
		dots: false,
		animateIn: 'animate__fadeIn animate__fast',
		animateOut: 'animate__fadeOut animate__fast',
		navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
		responsive: { 0: { items: 1 } },
	};

	doAction(slide: IActivity): void {
		this.router.navigate([`activity/${slide.id}`]);
	}
}
