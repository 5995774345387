<owl-carousel-o id="popup-slider" #owlCar [options]="mainOps">
	@for (slide of mainSlides; track slide.id) {
		<ng-template [id]="slide.id" carouselSlide>
			<div class="w-100 d-flex align-items-center justify-content-around justify-content-lg-center gap-1 py-1">
				<button class="button-unstyled" (click)="owlCar.prev()" styles="30px">
					<i class="d-block fas fa-chevron-left arrows"></i>
				</button>
				<button class="button-unstyled bannerText px-md-3 px-lg-5" (click)="doAction(slide)">
					{{ slide.bannerTextMain | i18nDataRead: uiSrv.curLangSgn() }}
				</button>
				@if (slide.bannerTextExtra | i18nDataRead: uiSrv.curLangSgn(); as bannerTextExtra) {
					@if (bannerTextExtra.length > 0) {
						<div class="d-none d-md-block bannerExtraText px-md-3 px-lg-5">
							{{ slide.bannerTextExtra | i18nDataRead: uiSrv.curLangSgn() }}
						</div>
					}
				}
				<div class="action-button-container mx-1 mx-md-3 mx-lg-5">
					<button class="action-button d-block btn btn-primary py-1 px-1 px-md-3 px-md-3 px-lg-5" (click)="doAction(slide)">
						SCOPRI
					</button>
				</div>
				<button class="button-unstyled" (click)="owlCar.next()" styles="30px">
					<i class="d-block fas fa-chevron-right arrows"></i>
				</button>
			</div>
		</ng-template>
	}
</owl-carousel-o>
