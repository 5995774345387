import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, afterNextRender, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { IActivity } from '@scb-lib/index';
import { SeoService } from '@shared/services/seo.service';
import { Observable } from 'rxjs';
import { BannerComponent } from './_shared/cmp/banner/banner.component';
import { LoaderComponent } from './_shared/cmp/loader/loader.component';
import { ToastMessageComponent } from './_shared/cmp/toast-message/toast-message.component';
import { OverlayService } from './_shared/overlay-module/overlay.service';
import { UiService } from './_shared/services/ui.service';
import { ActivityReaderService } from './activities/activities-reader.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavBarComponent } from './_shared/cmp/nav-bar/nav-bar.component';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [RouterOutlet, ToastMessageComponent, BannerComponent, LoaderComponent, AsyncPipe, MatSidenavModule, NavBarComponent],
})
export class AppComponent {
	cd = inject(ChangeDetectorRef);
	title = 'scbweb-web';
	activitiesToShow$: Observable<{ banner: IActivity[]; popup: IActivity[] }> | undefined;

	constructor(
		public uiSrv: UiService,
		private ovSrv: OverlayService,
		private actReaderSrv: ActivityReaderService,
		private seoSrv: SeoService // PDL must instantiate to make it start working
	) {
		afterNextRender(() => {
			// this.activitiesToShow$ = this.uiSrv.isStable$.pipe(
			// 	first(),
			// 	switchMap(() =>
			// 		zip(this.actReaderSrv.getFutureActivitiesVisibleInBanner(), this.actReaderSrv.getFutureActivitiesVisibleInPopup())
			// 	),
			// 	map(([banner, popup]) => ({ banner, popup })),
			// 	tap((activities) => {
			// 		if (activities.banner.length) {
			// 			this.uiSrv.bannerVisible.set(true);
			// 		}
			// 		if (activities.popup.length) {
			// 			this.ovSrv.open(PopupComponent, { data: activities.popup });
			// 		}
			// 	})
			// );
		});
	}
}
