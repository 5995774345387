import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, LOCALE_ID, isDevMode } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideClientHydration, withEventReplay, withI18nSupport } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { IsAuthedGuard } from '@shared/guards/authed.guard';
import { RedirectToUrlGuard } from '@shared/guards/redirectToUrl.guard';
import { UiService } from '@shared/services/ui.service';
import { SCBWEB_FIREBASE_CONFIG } from '../environments/environment';
import { APP_ROUTES } from './app.routes';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';

export const appConfig: ApplicationConfig = {
	providers: [
		provideClientHydration(withI18nSupport(), withEventReplay()),
		provideAnimations(),
		provideHttpClient(withFetch()),
		provideFirebaseApp(() => initializeApp(SCBWEB_FIREBASE_CONFIG)),
		provideAuth(() => getAuth()),
		provideFirestore(() => getFirestore()),
		provideRouter(
			APP_ROUTES,
			withInMemoryScrolling({ scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
			withViewTransitions(),
			withComponentInputBinding()
		),
		{
			provide: LOCALE_ID,
			useFactory: (uiSrv: UiService) => uiSrv.getCurLangValue(),
			deps: [UiService],
		},
		IsAuthedGuard,
		RedirectToUrlGuard,
		provideHttpClient(),
		provideTransloco({
			config: {
				availableLangs: ['it', 'en'],
				defaultLang: 'it',
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				fallbackLang: 'it',
				missingHandler: {
					// It will use the first language set in the `fallbackLang` property
					useFallbackTranslation: true,
				},
				prodMode: !isDevMode(),
			},
			loader: TranslocoHttpLoader,
		}),
	],
};
