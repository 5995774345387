<mat-sidenav-container class="example-container">
	<mat-sidenav-content>
		<router-outlet></router-outlet>
	</mat-sidenav-content>
	<mat-sidenav [opened]="uiSrv.sidenavVisible()" [position]="'end'" mode="over"> sidenav content </mat-sidenav>
</mat-sidenav-container>

<app-toast-message id="toastComponent"></app-toast-message>
<!-- 
@if (activitiesToShow$ | async; as activitiesToShow) {
	@if (activitiesToShow.banner.length > 0) {
		<app-banner id="banner" [mainSlides]="activitiesToShow.banner"></app-banner>
	}
} -->

<app-loader type="apploader"></app-loader>
