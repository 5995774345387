import { Component, OnDestroy, OnInit } from '@angular/core';
import { TOAST_DURATIONS, UiService } from 'app/_shared/services/ui.service';
import { of, Subject } from 'rxjs';
import { filter, switchMap, delay, tap, takeUntil, map } from 'rxjs/operators';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-toast-message',
	templateUrl: './toast-message.component.html',
	styleUrls: ['./toast-message.component.scss'],
	standalone: true,
	imports: [NgClass],
})
export class ToastMessageComponent implements OnInit, OnDestroy {
	private onDestroy$: Subject<void> = new Subject();

	msg: string | null = null;
	showMsg = false;
	isErrMsg = false;
	isSuccessMsg = false;

	constructor(private uiSrv: UiService) {}

	ngOnInit() {
		this.uiSrv.toastMsgQueue$
			.pipe(
				map((queue) => queue?.[0] ?? null),
				filter((toastMsg) => !!toastMsg),
				switchMap((toastMsg) => {
					this.msg = toastMsg.msg;
					this.isErrMsg = toastMsg.isErrorMsg ?? false;
					this.isSuccessMsg = toastMsg.isSuccessMsg ?? false;
					if (this.msg.length > 500) {
						this.msg = this.msg.substr(0, 499) + '&hellip;';
					}

					const duration =
						toastMsg.duration !== undefined
							? toastMsg.duration
							: this.msg.length > 300
							? TOAST_DURATIONS.long
							: this.msg.length > 100
							? TOAST_DURATIONS.medium
							: TOAST_DURATIONS.short;

					this.showMsg = true;

					return of(null).pipe(delay(duration));
				}),
				tap((_) => {
					this.showMsg = false;
					this.msg = null;
					this.uiSrv.shiftTostMsgFromQueue();
				}),
				takeUntil(this.onDestroy$)
			)
			.subscribe();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
