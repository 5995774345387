import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
export interface RedirectToUrlData {
	url: string;
	preserveQueryParams?: boolean;
	queryParams?: Params;
}

@Injectable({
	providedIn: 'root',
})
export class RedirectToUrlGuard implements CanActivate {
	document = inject(DOCUMENT);
	router = inject(Router);

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		const data = route.data.redirectToUrlData as RedirectToUrlData;
		let qp: Params = {};
		if (data.preserveQueryParams) {
			qp = { ...qp, ...route.queryParams };
		}
		if (data.queryParams) {
			qp = { ...qp, ...data.queryParams };
		}
		try {
			const urlExt = new URL(data.url); // if not a valid external link it fails
			Object.entries(qp).forEach(([key, value]) => {
				urlExt.searchParams.append(key, value as string);
			});
			console.log('Redirecting to external URL:', urlExt.toString(), urlExt);
			this.document.location.href = urlExt.toString();
			return of(false);
		} catch (e) {
			// console.log('Not a valid external URL');
		}
		console.log('Redirecting to URL:', data.url, 'with query params:', qp);
		this.router.navigate([data.url], {
			queryParamsHandling: 'merge',
			queryParams: qp,
		});

		return of(false);
	}
}
