import { redirectPreservingGuardFactory } from '@shared/guards/redirect-preserving.guard';
import { RouteExt, RoutesExt } from '@shared/router-utils';
import { PageNotFoundComponent } from './_shared/cmp/page-not-found/page-not-found.component';
import { RedirectToUrlGuard } from './_shared/guards/redirectToUrl.guard';
import { BookingService } from './booking/booking-service';

const indexRoute: RouteExt = {
	path: '',
	pathMatch: 'full',
	canActivate: [RedirectToUrlGuard],
	data: { redirectToUrlData: { url: '/home', preserveQueryParams: true } },
	loadComponent: () => import('./_shared/cmp/empty/empty.component'),
};
// Page not found
const fallbackRoute: RouteExt = {
	path: '**',
	component: PageNotFoundComponent,
};

export const APP_ROUTES: RoutesExt = [
	{ path: 'home', loadComponent: () => import('./home/cmp/home/home.component') },
	{ path: 'location', loadChildren: () => import('./location/location.routes').then((r) => r.LOCATION_ROUTES) },
	{ path: 'auth', loadChildren: () => import('./auth/auth.routes').then((r) => r.AUTH_ROUTES) },
	{ path: 'user', loadChildren: () => import('./auth/user.routes').then((r) => r.USER_ROUTES) },
	{ path: 'crm', loadChildren: () => import('./crm/crm.routes').then((r) => r.CRM_ROUTES) },
	{ path: 'coupon', loadChildren: () => import('./coupon/coupon.routes').then((r) => r.COUPON_ROUTES) },

	{ path: 'offer', loadChildren: () => import('./offer/offer.routes').then((r) => r.OFFER_ROUTES) },
	{
		path: 'menu',
		canActivate: [RedirectToUrlGuard],
		data: { redirectToUrlData: { url: '/offer/main', preserveQueryParams: true } },
		loadComponent: () => import('./_shared/cmp/empty/empty.component'),
	},
	{ path: 'menus', loadChildren: () => import('./menus/menus.routes').then((r) => r.MENUS_ROUTES) },
	{ path: 'qr-menu', redirectTo: '/offer/main' },
	{
		path: 'workdaymenu',
		loadComponent: () => import('./_shared/cmp/empty/empty.component'),
		canActivate: [RedirectToUrlGuard],
		data: {
			redirectToUrlData: { url: 'https://workdaymenu.saycheesebistrot.com', preserveQueryParams: true },
		},
		devComment: 'PDL this is only for development as redirect is set in frebase hosting settings as well',
	},

	{ path: 'book', loadChildren: () => import('./booking/booking.routes').then((r) => r.BOOKING_ROUTES), providers: [BookingService] },

	{ path: 'activities', loadChildren: () => import('./activities/activities.routes').then((r) => r.ACTIVITIES_ROUTES) },
	{ path: 'activity', loadChildren: () => import('./activities/activities.routes').then((r) => r.ACTIVITY_ROUTES) },

	// PDL OLD ROUTES REDIRECTS. ONLY FOR DEVELOPMENT TEST AS THEY ARE ALSO SET IN FIREBASE HOSTING SETTINGS
	{
		path: 'booking',
		loadComponent: () => import('./_shared/cmp/empty/empty.component'),
		children: [
			{
				path: '**',
				loadComponent: () => import('./_shared/cmp/empty/empty.component'),
				canActivate: [redirectPreservingGuardFactory({ segmentToReplace: 'booking', segmentToReplaceWith: 'book' })],
			},
		],
	},
	{
		path: 'events',
		children: [
			{
				path: '**',
				loadComponent: () => import('./_shared/cmp/empty/empty.component'),
				canActivate: [redirectPreservingGuardFactory({ segmentToReplace: 'events', segmentToReplaceWith: 'activities' })],
			},
		],
	},
	{
		path: 'act',
		children: [
			{
				path: '**',
				loadComponent: () => import('./_shared/cmp/empty/empty.component'),
				canActivate: [redirectPreservingGuardFactory({ segmentToReplace: 'act', segmentToReplaceWith: 'activity' })],
			},
		],
	},
	{
		path: 'event',
		children: [
			{
				path: '**',
				loadComponent: () => import('./_shared/cmp/empty/empty.component'),
				canActivate: [redirectPreservingGuardFactory({ segmentToReplace: 'event', segmentToReplaceWith: 'activity' })],
			},
		],
	},

	indexRoute,
	fallbackRoute,
];
