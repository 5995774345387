import { MatIconRegistry } from '@angular/material/icon';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig)
	.then((app) => {
		const matIconRegistry = app.injector.get(MatIconRegistry);
		matIconRegistry.setDefaultFontSetClass('material-symbols-rounded');
		// if (!environment.production) tasks(app)
		console.log('CLIENT BOOTSTRAP');
		return app;
	})
	.catch((err) => console.error(err));

// tasks monitoring for ssr
// import { ApplicationRef, NgZone, inject, runInInjectionContext } from '@angular/core';
// import 'zone.js/plugins/task-tracking';
// import { environment } from './environments/environment';

// const tasks = (app: ApplicationRef) =>
// 	runInInjectionContext(app.injector, () => {
// 		const ngZone = inject(NgZone);
// 		/**
// 		 * CONFIGURE how long to wait (in seconds)
// 		 * before the pending tasks are dumped to the console.
// 		 */
// 		const WAIT_SECONDS = 20;

// 		console.log(`⏳ ... Wait ${WAIT_SECONDS} seconds to dump pending tasks ... ⏳`);

// 		// Run the debugging `setTimeout` code outside of
// 		// the Angular Zone, so it's not considered as
// 		// yet another pending Zone Task:
// 		ngZone.runOutsideAngular(() => {
// 			setTimeout(() => {
// 				// Access the NgZone's internals - TaskTrackingZone:

// 				// eslint-disable-next-line @typescript-eslint/no-explicit-any
// 				const TaskTrackingZone = (ngZone as any)._inner._parent._properties.TaskTrackingZone;

// 				// Print to the console all pending tasks
// 				// (micro tasks, macro tasks and event listeners):
// 				console.debug('👀 Pending tasks in NgZone: 👀');

// 				const tasks = {
// 					microTasks: TaskTrackingZone.getTasksFor('microTask'),
// 					macroTasks: TaskTrackingZone.getTasksFor('macroTask'),
// 					eventTasks: TaskTrackingZone.getTasksFor('eventTask'),
// 				};
// 				console.debug(tasks);
// 				// console.debug(JSON.stringify(tasks, getCircularReplacer(), 2));
// 				tasks.macroTasks.forEach((t: unknown) => {
// 					// eslint-disable-next-line @typescript-eslint/no-explicit-any
// 					console.debug((t as any).creationLocation.stack);
// 				});

// 				// Advice how to find the origin of Zone tasks:
// 				console.debug(
// 					`👀 For every pending Zone Task listed above investigate the stacktrace in the property 'creationLocation' 👆`
// 				);
// 			}, 1000 * WAIT_SECONDS);
// 		});
// 	});

// const getCircularReplacer = () => {
// 	const seen = new WeakSet();
// 	return (key: string, value: unknown) => {
// 		if (typeof value === 'object' && value !== null) {
// 			if (seen.has(value)) {
// 				return;
// 			}
// 			seen.add(value);
// 		}
// 		return value;
// 	};
// };
